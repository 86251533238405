import React, { FC, useState } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import { useMediaQuery } from 'react-responsive';
import Stepper from '../molecules/Stepper/Stepper';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import hips_1_female from '../../../assets/questionnaire/hips/hips-1-female.svg';
import hips_2_female from '../../../assets/questionnaire/hips/hips-2-female.svg';
import hips_3_female from '../../../assets/questionnaire/hips/hips-3-female.svg';

import hips_1_male from '../../../assets/questionnaire/hips/hips-1-male.svg';
import hips_2_male from '../../../assets/questionnaire/hips/hips-2-male.svg';
import hips_3_male from '../../../assets/questionnaire/hips/hips-3-male.svg';

import './stepHips.scss';

interface IPropsHipsChoice {
  image: string;
  onClick: () => void;
  text: string;
  isSelected?: boolean;
}

const HipsChoice: FC<IPropsHipsChoice> = ({
  image,
  onClick,
  text,
  isSelected
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <button
      className={`hips_choice ${
        isSelected ? 'hips_choice--selected' : undefined
      }`}
      onClick={onClick}
    >
      {loading === true ? (
        <Lottie
          className="hips_choice__loading"
          animationData={require('../../../assets/animations/loading_image.json')}
        />
      ) : null}
      <img
        className={`hips_choice__image ${
          loading ? 'hips_choice__image--loading' : undefined
        }`}
        src={image}
        onLoad={() => setLoading(false)}
      />
      {text}
    </button>
  );
};

interface IPropsStepHips {
  gender: string;
  nextStep: () => void;
  skip: () => void;
  value: {
    current: number;
  };
}

const StepHips: FC<IPropsStepHips> = ({ gender, nextStep, skip, value }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/hips');
  const [selected, setSelected] = useState<number | undefined>(value.current);

  return (
    <div className="hips">
      <div className="hips__text">
        {isMobile ? <p className="hips__text__title">{t('title')}</p> : null}
        <p className="hips__text__description">{t('description')}</p>
      </div>
      <div className="hips__body">
        <HipsChoice
          image={gender === 'male' ? hips_1_male : hips_1_female}
          onClick={() => {
            value.current = 1;
            setSelected(1);
          }}
          text={t('size.one')}
          isSelected={selected === 1}
        />
        <HipsChoice
          image={gender === 'male' ? hips_2_male : hips_2_female}
          onClick={() => {
            value.current = 2;
            setSelected(2);
          }}
          text={t('size.two')}
          isSelected={selected === 2}
        />
        <HipsChoice
          image={gender === 'male' ? hips_3_male : hips_3_female}
          onClick={() => {
            value.current = 3;
            setSelected(3);
          }}
          text={t('size.three')}
          isSelected={selected === 3}
        />
      </div>
      <Stepper
        stepsNum={gender === 'male' ? 3 : 2}
        step={2}
        key={1}
        className="hips__stepper"
      />
      <div className="hips__buttons">
        <button
          disabled={selected === undefined || selected === 0}
          className="hips__buttons__continue"
          onClick={() => nextStep()}
        >
          {t('buttons.continue')}
        </button>
        {false && (
          <button className="hips__buttons__scan" onClick={() => skip()}>
            <CameraAltOutlinedIcon className="hips__buttons__scan__icon" />
            {t('buttons.scan')}
          </button>
        )}
      </div>
    </div>
  );
};

export default StepHips;
