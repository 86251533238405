import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useMediaQuery } from 'react-responsive';
import AgeDesktop from './desktop/desktop';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import './stepMeasure.scss';
import AgeMobile from './mobile/mobile';

interface IPropsStepAge {
  nextStep: () => void;
  age: {
    current?: number;
  };
}

const StepAge: FC<IPropsStepAge> = ({ nextStep, age }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/measures');
  const userStored = getLocalAndParse('user');
  const [units, setUnits] = useState({
    height: 'cm',
    weight: 'kg'
  });
  const [localAge, setLocalAge] = useState(userStored?.age ?? 35);
  const [error, setError] = useState({
    age: {
      error: false,
      message: ''
    }
  });

  const saveUserInformation = () => {
    const newUser = {
      ...userStored,
      age: localAge
    };

    age.current = localAge;

    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const handleContinue = () => {
    const newError = {
      age: {
        error: false,
        message: ''
      }
    };
    if (!localAge) {
      newError.age.error = true;
      newError.age.message = t('errors.required');
    }
    if (localAge && (localAge < 16 || localAge > 80)) {
      newError.age.error = true;
      newError.age.message = t('errors.age');
    }

    setError(newError);

    if (!newError.age.error) {
      saveUserInformation();
      nextStep();
    }
  };

  useEffect(() => {
    const newError = {
      age: {
        error: false,
        message: ''
      }
    };
    if (!localAge) {
      newError.age.error = true;
      newError.age.message = t('errors.required');
    }
    if (localAge && (localAge < 16 || localAge > 80)) {
      newError.age.error = true;
      newError.age.message = t('errors.age');
    }

    setError(newError);
  }, [localAge]);

  useEffect(() => {
    const newUser = {
      ...userStored,
      age: localAge
    };

    localStorage.setItem('user', JSON.stringify(newUser));
  }, [localAge]);

  return (
    <div className="measures-age">
      {isMobile ? (
        <AgeMobile
          units={units}
          setUnits={setUnits}
          age={[localAge]}
          setAge={setLocalAge}
          error={error.age}
        />
      ) : (
        <AgeDesktop
          units={units}
          setUnits={setUnits}
          age={[localAge]}
          setAge={setLocalAge}
          error={error.age}
        />
      )}
      <button
        disabled={error.age.error}
        className="measures-age__continue"
        onClick={handleContinue}
      >
        <Trans
          ns="components/measures"
          i18nKey="age.continue"
          values={{
            age: localAge
          }}
        />
        <EastRoundedIcon className="measures-age__continue__icon" />
      </button>
    </div>
  );
};

export default StepAge;
