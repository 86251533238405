import React, { FC, useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';
import QuestionsMobile from './mobile/mobile';
import QuestionsDesktop from './desktop/desktop';

interface IPropsStepQuestions {
  object?: any;
  nextStep: () => void;
}

const StepQuestions: FC<IPropsStepQuestions> = ({ object, nextStep }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  console.log('objectInit', object);
  return (
    <>
      {isMobile ? (
        <QuestionsMobile object={object} nextStep={nextStep} />
      ) : (
        <QuestionsDesktop object={object} nextStep={nextStep} />
      )}
    </>
  );
};

export default StepQuestions;
