import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    ns: 'components/intro',
    defaultNS: 'components/intro',
    debug: false,
    interpolation: {
      escapeValue: false
    }
    /*backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }*/
  });

/* Add your translation file below to preload it */
i18n.loadNamespaces([
  'components/modal',
  'components/belly',
  'components/breasts',
  'components/hips',
  'components/intro',
  'components/questions',
  'components/measures',
  'components/qrcode/desktop',
  'components/qrcode/mobile',
  'components/results/error',
  'components/results/loading',
  'components/results/result',
  'components/torso'
]);

export { i18n };
