import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useUserContext } from '../../../../store/userContext';

import './stepIntro.scss';

interface IPropsStepIntroMobile {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
}

const StepIntroMobile: FC<IPropsStepIntroMobile> = ({
  nextStep,
  setLocalGender
}) => {
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const [selectGender, setSelectGender] = useState<string | undefined>(
    userStored?.gender
  );

  const handleNext = (gender: string) => {
    const newUser = {
      ...userStored,
      gender: gender
    };

    localStorage.setItem('user', JSON.stringify(newUser));
    setLocalGender(gender);

    if (setUser) {
      setUser(newUser);
    }

    gender !== undefined && nextStep();
  };

  return (
    <div className="intro-mobile">
      <div className="intro-mobile__text">
        <h1 className="intro-mobile__text__title">
          <Trans
            ns="components/intro"
            i18nKey="mobile.title"
            values={{ separator: '\n' }}
          />
        </h1>
        <p className="intro-mobile__text__description">
          {t('mobile.description')}
        </p>
      </div>
      <div className="intro-mobile__buttons">
        <button
          className={`intro-mobile__buttons__button ${
            selectGender === 'male'
              ? 'intro-mobile__buttons__button--active'
              : undefined
          }`}
          onClick={() => {
            setLocalGender('male');
            setSelectGender('male');
          }}
        >
          {t('gender.male')}
        </button>
        <button
          className={`intro-mobile__buttons__button ${
            selectGender === 'female'
              ? 'intro-mobile__buttons__button--active'
              : undefined
          }`}
          onClick={() => {
            setLocalGender('female');
            setSelectGender('female');
          }}
        >
          {t('gender.female')}
        </button>
      </div>
      <button
        className="intro-mobile__buttons__continue"
        disabled={selectGender === undefined}
        onClick={() => handleNext(selectGender || '')}
      >
        {t('continue')}
      </button>
    </div>
  );
};

export default StepIntroMobile;
