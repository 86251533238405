import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useUserContext } from '../../../../store/userContext';

import './stepIntro.scss';

interface IPropsStepIntroDesktop {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
}

const StepIntroDesktop: FC<IPropsStepIntroDesktop> = ({
  nextStep,
  setLocalGender
}) => {
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const [selectGender, setSelectGender] = useState<string | undefined>(
    userStored?.gender
  );

  const handleNext = (gender: string) => {
    const newUser = {
      ...userStored,
      gender: gender
    };

    localStorage.setItem('user', JSON.stringify(newUser));
    setLocalGender(gender);

    if (setUser) {
      setUser(newUser);
    }

    gender !== undefined && nextStep();
  };

  return (
    <div className="intro-desktop">
      <div className="intro-desktop__block" />
      <div className="intro-desktop__container">
        <h1 className="intro-desktop__container__title">
          <Trans
            ns="components/intro"
            i18nKey="desktop.title"
            values={{ separator: '\n' }}
          />
        </h1>
        <p className="intro-desktop__container__description">
          {t('desktop.description')}
        </p>
        <div className="intro-desktop__container__buttons">
          <button
            className={`intro-desktop__container__buttons__button ${
              selectGender === 'male'
                ? 'intro-desktop__container__buttons__button--active'
                : undefined
            }`}
            onClick={() => {
              setLocalGender('male');
              setSelectGender('male');
            }}
          >
            {t('gender.male')}
          </button>
          <button
            className={`intro-desktop__container__buttons__button ${
              selectGender === 'female'
                ? 'intro-desktop__container__buttons__button--active'
                : undefined
            }`}
            onClick={() => {
              setLocalGender('female');
              setSelectGender('female');
            }}
          >
            {t('gender.female')}
          </button>
        </div>
        <button
          className="intro-desktop__container__buttons__continue"
          disabled={selectGender === undefined}
          onClick={() => handleNext(selectGender || '')}
        >
          {t('continue')}
        </button>
      </div>
    </div>
  );
};

export default StepIntroDesktop;
