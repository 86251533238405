import React, { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '../../molecules/Input/Text';

import './desktop.scss';

import { getLocalAndParse } from '../../../../store/localStoreUtils';

interface IPropsQuestionsDesktop {
  object?: any;
  nextStep: () => void;
}

const QuestionsDesktop: FC<IPropsQuestionsDesktop> = ({ object, nextStep }) => {
  const { t } = useTranslation('components/questions');

  const [transformValue, setTransformValue] = useState('translateX(101%)'); // Démarre hors de l'écran
  const [opacity, setOpactity] = useState(0);
  const [disabled, setDisabled] = useState(
    object.reduce((acc: any, { name }: keyof object) => {
      acc[name] = false;
      return acc;
    }, {})
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setTransformValue('translateX(0%)'); // Déplace vers la position initiale
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpactity(1);
    }, 800);
  }, []);

  const handleNext = () => {
    const previousUser = getLocalAndParse('user');
    console.log('previousUser', previousUser);
    const newInputs = object.reduce((acc: any, { name, inputRef }: any) => {
      acc[name] = inputRef.current;
      return acc;
    }, {});
    const newUser = { ...previousUser, ...newInputs };
    localStorage.setItem('user', JSON.stringify(newUser));
    nextStep();
  };

  return (
    <div className="questions-desktop">
      <div className="questions-desktop__block" />
      <div
        className="questions-desktop__container"
        style={{
          transform: transformValue
        }}
      >
        <div
          className="questions-desktop__container__content"
          style={{
            opacity: opacity
          }}
        >
          <h1 className="questions-desktop__container__content__title">
            <Trans
              ns="components/questions"
              i18nKey="title"
              values={{ separator: '\n' }}
            />
          </h1>
          {Object.keys(object).map((idx) => {
            return (
              <Text key={idx} object={object[idx]} setDisabled={setDisabled} />
            );
          })}
          <button
            onClick={handleNext}
            className="questions-desktop__container__content__button"
            disabled={Object.values(disabled).some((value) => value === true)}
          >
            {t('continue')}
          </button>
          <div className="questions-desktop__container__content__copyright">
            <p>
              {t('pp_part1')}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.kleep.ai/legal"
                style={{ textDecoration: 'underline' }}
              >
                {t('pp_link')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsDesktop;
