import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import './products.scss';
import Carousel from '../molecules/Carousel/Carousel';
import { similarProducts } from '../../../api/endpoints';

interface IPropsProducts {
  pid?: string;
}

const Products: FC<IPropsProducts> = ({ pid }) => {
  const [similarProductsList, setSimilarProductsList] =
    useState<
      { title: string; brand: string; image_url: string; product_url: string }[]
    >();

  useEffect(() => {
    similarProducts(pid).then((value) => {
      console.log(value);
      if (value) setSimilarProductsList(value);
    });
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const products = [
    {
      title: 'Doudoune noire',
      brand: 'Columbia',
      picture:
        'https://oraije.com/cdn/shop/files/C1603VVERT.jpg?v=1689064274&width=1670',
      url: 'https://www.google.com'
    },
    {
      title: 'Veste légère',
      brand: 'Jott',
      picture:
        'https://cdn.shopify.com/s/files/1/0514/3324/2824/products/JD409KKAKI1.jpg?v=1698747370&width=1002',
      url: 'https://www.google.com'
    },
    {
      title: 'Doudoune noire',
      brand: 'Columbia',
      picture:
        'https://oraije.com/cdn/shop/files/C1603VVERT.jpg?v=1689064274&width=1670',
      url: 'https://www.google.com'
    },
    {
      title: 'Veste légère',
      brand: 'Jott',
      picture:
        'https://cdn.shopify.com/s/files/1/0514/3324/2824/products/JD409KKAKI1.jpg?v=1698747370&width=1002',
      url: 'https://www.google.com'
    },
    {
      title: 'Doudoune noire',
      brand: 'Columbia',
      picture:
        'https://oraije.com/cdn/shop/files/C1603VVERT.jpg?v=1689064274&width=1670',
      url: 'https://www.google.com'
    },
    {
      title: 'Veste légère',
      brand: 'Jott',
      picture:
        'https://cdn.shopify.com/s/files/1/0514/3324/2824/products/JD409KKAKI1.jpg?v=1698747370&width=1002',
      url: 'https://www.google.com'
    },
    {
      title: 'Doudoune noire',
      brand: 'Columbia',
      picture:
        'https://oraije.com/cdn/shop/files/C1603VVERT.jpg?v=1689064274&width=1670',
      url: 'https://www.google.com'
    },
    {
      title: 'Veste légère',
      brand: 'Jott',
      picture:
        'https://cdn.shopify.com/s/files/1/0514/3324/2824/products/JD409KKAKI1.jpg?v=1698747370&width=1002',
      url: 'https://www.google.com'
    }
  ];

  return (
    <div className="products">
      <Carousel
        itemsPerPage={isMobile ? 2 : 3}
        products={similarProductsList}
      />
    </div>
  );
};

export default Products;
