import React, { FC, useState, useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './Carousel.scss';

interface IPropsCarouselStepper {
  stepsNum: number;
  step: number;
  className?: string;
  onStepClick: (step: number) => void;
}

const CarouselStepper: FC<IPropsCarouselStepper> = ({
  stepsNum,
  step,
  className,
  onStepClick
}) => {
  const stepRender = (stepNum: number, step: number) => {
    const steps = [];

    for (let i = 0; i < stepNum; i += 1)
      steps.push(
        <div
          key={i}
          className={`carousel-stepper__step ${
            i + 1 === step
              ? 'carousel-stepper__step--active'
              : 'carousel-stepper__step--inactive'
          }`}
          onClick={() => {
            if (i + 1 !== step) {
              onStepClick(i + 1);
            }
          }}
        />
      );

    return steps;
  };

  return (
    <div className={`stepper ${className}`}>{stepRender(stepsNum, step)}</div>
  );
};

interface IPropsCarousel {
  itemsPerPage: number;
  products?: {
    title: string;
    brand: string;
    image_url: string;
    product_url: string;
  }[];
}

const Carousel: FC<IPropsCarousel> = ({ itemsPerPage, products }) => {
  if (!products) return null;

  const [showArrows, setShowArrows] = useState({
    left: false,
    right: false
  });
  const [step, setStep] = useState(0);

  const isVisible = (element: HTMLElement) => {
    const container = document.getElementById('carousel');
    const { left, right } = element.getBoundingClientRect();
    const containerRect = container?.getBoundingClientRect();

    if (
      containerRect &&
      (left < containerRect?.left || right > containerRect?.right)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleArrows = () => {
    const element = document.getElementById('carousel');
    let arrows = { left: false, right: false };

    if (element) {
      if (element.scrollLeft <= 0) {
        arrows = { ...arrows, left: false };
      } else if (
        element.scrollLeft >=
        element.scrollWidth - element.offsetWidth
      ) {
        arrows = { ...arrows, right: false };
      }

      if (element.scrollLeft > 0) {
        arrows = { ...arrows, left: true };
      }
      if (element.scrollLeft < element.scrollWidth - element.offsetWidth) {
        arrows = { ...arrows, right: true };
      }

      setShowArrows(arrows);
    }
  };

  const leftArrowMove = () => {
    const container = document.getElementById(`carousel`);
    let isBreak = false;
    let firstVisibility: boolean | undefined = undefined;
    let goToElement: string | undefined = undefined;

    [].slice.call(container?.children).forEach((ele: HTMLElement) => {
      if (firstVisibility === false && isVisible(ele) === true) {
        isBreak = true;
      }
      if (isBreak === false) {
        goToElement = ele.id;
      }

      firstVisibility = isVisible(ele);
    });

    const element = document.getElementById(goToElement || '');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  };

  const RightArrowMove = () => {
    const container = document.getElementById(`carousel`);
    let lastVisibility: boolean | undefined = undefined;
    let goToElement: string | undefined = undefined;

    [].slice.call(container?.children).forEach((ele: HTMLElement) => {
      if (lastVisibility === true && isVisible(ele) === false) {
        goToElement = ele.id;
      }

      lastVisibility = isVisible(ele);
    });

    const element = document.getElementById(goToElement || '');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  };

  const itemsPagesFormatter = () => {
    const pages = [];

    const pageFormater = (index: number) => {
      const page = [];

      for (
        let i = 0, j = index;
        i < itemsPerPage && j < products.length;
        i += 1, j += 1
      ) {
        page.push(
          <a
            href={products[j].product_url}
            className="carousel__container__pages__page__item"
            target="_blank"
            rel="noreferrer"
            key={`carousel-page-${index}-item-${i}`}
          >
            <div
              className="carousel__container__pages__page__item__image"
              style={{
                backgroundImage: `url(${products[j].image_url})`
              }}
            />
            <div className="carousel__container__pages__page__item__info">
              <div className="carousel__container__pages__page__item__info__title">
                {products[j].title}
              </div>
              <p>{products[j].brand}</p>
            </div>
          </a>
        );
      }

      return page;
    };

    for (let i = 0, j = 0; i < products.length; i += itemsPerPage, j += 1) {
      pages.push(
        <div
          id={`carousel-page-${j}`}
          key={`carousel-page-${j}`}
          className="carousel__container__pages__page"
        >
          {pageFormater(i)}
        </div>
      );
    }

    return pages;
  };

  const getStep = () => {
    const container = document.getElementById('carousel');

    [].slice.call(container?.children).forEach((ele: HTMLElement) => {
      if (isVisible(ele) === true) {
        setStep(parseInt(ele.id.split('-')[2]) + 1);
      }
    });
  };

  useEffect(() => {
    const element = document.getElementById('carousel');

    handleArrows();
    getStep();
    element?.addEventListener('scroll', () => handleArrows());
    element?.addEventListener('scroll', () => getStep());
  }, []);
  return (
    <div className="carousel">
      <div className="carousel__container">
        <ArrowBackIosNewIcon
          onClick={() => showArrows.left && leftArrowMove()}
          className={`carousel__container__arrows carousel__container__arrows--${
            showArrows.left ? 'active' : 'inactive'
          }`}
        />
        <div id="carousel" className="carousel__container__pages">
          {itemsPagesFormatter()}
        </div>
        <ArrowForwardIosIcon
          onClick={() => showArrows.right && RightArrowMove()}
          className={`carousel__container__arrows carousel__container__arrows--${
            showArrows.right ? 'active' : 'inactive'
          }`}
        />
      </div>
      {/* <p className="carousel__stepper">{`${step} / ${Math.ceil(
        products.length / itemsPerPage
      )}`}</p> */}
    </div>
  );
};

export default Carousel;
