import React, { useEffect, useState } from 'react';

import { getLocalAndParse, setLocalStore } from './store/localStoreUtils';

import Product from './components/product/Product';
import { useAppContext } from './context/AppContext';

const App = () => {
  const {
    referer,
    pid,
    setPid,
    setReferer,
    extractPidFromUrl,
    extractRefererFromUrl
  } = useAppContext() || {};

  const result = getLocalAndParse('productSize');

  // Set the maximum loop count
  const [loopCount, setLoopCount] = useState(0);
  const MAX_LOOP_COUNT = 4;

  const sendResult = () => {
    const params = {
      result: ' ',
      initial: getLocalAndParse(String(pid))
    };
    window.parent.postMessage(params, '*');
  };

  /* send hash */
  const sendHash = () => {
    const params = {
      result: 'Hash',
      Hash: localStorage.getItem('localHash'),
      bucket_name: getLocalAndParse('brandData')?.bucket_name?.toLowerCase()
    };
    window.parent.postMessage(params, '*');
  };

  useEffect(() => {
    getLocalAndParse(String(pid)) ? sendResult() : null;
    if (loopCount < MAX_LOOP_COUNT) {
      setLoopCount(loopCount + 1);
    }
  }, [loopCount]);

  useEffect(() => {
    typeof localStorage.getItem('localHash') === 'string' ? sendHash() : null;
  }, []);

  // use useEffect to add an event listener when the component mounts
  useEffect(() => {
    const handleUrlChange = () => {
      if (!!extractPidFromUrl && !!setPid) {
        const initialPid = extractPidFromUrl();
        if (initialPid) {
          setPid(initialPid);
          localStorage.setItem('pid', initialPid);
        }
      }

      if (!!extractRefererFromUrl && !!setReferer) {
        const initialReferer = extractRefererFromUrl();
        if (initialReferer) {
          setReferer(initialReferer);
          localStorage.setItem('referer', initialReferer);
        }
      }
    };

    // add an event listener to detect changes in the URL
    window.addEventListener('popstate', handleUrlChange);

    // clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [extractPidFromUrl, extractRefererFromUrl]);

  return <Product pid={pid} />;
};

export default App;
