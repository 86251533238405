import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './mobile.scss';
import Ruler from '../../../molecules/Ruler/Ruler';

interface IPropsWeightMobile {
  units: { height: string; weight: string };
  setUnits: (value: { height: string; weight: string }) => void;
  weight: number[] | undefined;
  setWeight: (value: number | undefined) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const WeightMobile: FC<IPropsWeightMobile> = ({
  units,
  setUnits,
  weight,
  setWeight,
  error
}) => {
  const { t } = useTranslation('components/measures');

  return (
    <div className="weight_mobile">
      <div className="weight_mobile__text">
        <p className="weight_mobile__text__title">{t('weight.title')}</p>
        <p className="weight_mobile__text__description">
          {t('weight.description')}
        </p>
      </div>
      <Ruler
        value={weight || [0]}
        onValueChange={(value) => {
          setWeight(value);
        }}
        units={['kg', 'lbs']}
        unit={units.weight}
        onUnitChange={(unit) => setUnits({ ...units, weight: unit })}
        error={error}
      />
    </div>
  );
};

export default WeightMobile;
