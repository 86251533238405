import React, {
  useContext,
  createContext,
  useEffect,
  useState,
  FC
} from 'react';

interface IAppContext {
  referer: string | null;
  pid: string | null;
  setPid: (value: string) => void;
  setReferer: (value: string) => void;
  extractRefererFromUrl: () => string | null;
  extractPidFromUrl: () => string | null;
}

const AppContext = createContext<IAppContext | null>(null);

interface IPropsAppProvider {
  children: JSX.Element;
}

const AppProvider: FC<IPropsAppProvider> = ({ children }) => {
  const [referer, setReferer] = useState<string | null>(null);
  const [pid, setPid] = useState<string | null>(null);

  function extractRefererFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('domain');
  }

  function extractPidFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('product_id');
  }

  useEffect(() => {
    const initialRefrer = extractRefererFromUrl();
    setReferer(initialRefrer);
  }, []);

  useEffect(() => {
    const initialPid = extractPidFromUrl();
    setPid(initialPid);
  }, []);

  return (
    <AppContext.Provider
      value={{
        referer,
        pid,
        setPid,
        setReferer,
        extractRefererFromUrl,
        extractPidFromUrl
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useAppContext = () => {
  return useContext(AppContext);
};
